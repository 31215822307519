import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import styled from "styled-components"
// import EpisodesSidebar from '../components/Episode/EpisodeSidebar'
// import CurrentEpisode from '../components/Episode/CurrentEpisode'
//

const EpisodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 6rem;

  @media (max-width: 50rem) {
    flex-direction: column;
  }
`
const EpisodeSidebar = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  max-width: 20rem;

  @media (max-width: 50rem) {
    order: 2;
    max-width: 100%;
    margin-top: 3rem;
  }
`

const EpisodeItem = styled.div`
  padding: 0;
  margin: 0;

  p {
    font-size: 1rem;
    text-align: left;
    padding: 1.25rem .5rem 0;
    margin: 0;
  }

  h3 {
    text-align: left;
    padding: 0 .5rem;
    margin-top: .5rem;
  }

  &:after {
    content:"";
    height:1px;
    width:100%;
    display:block;
    position:relative;
    background-image: var(--bluegrad);
    transform: scaleY(-1);
  }

  &:hover {
    background: var(--darkline);
  }
`
const CurrentEpisodeStyle = styled.div`
  padding: 0 2rem;
  width: 100%;

  p {
    text-align: left;
    width: 100%;
  }
`

// const embedLink = (link) => {
//   // const newEmbed = 'https://embed.sounder.fm/play/' + link.slice(37, 42) + '?options=cc'
//   // console.log(newEmbed)
//   const newEmbed = 'https://embed.sounder.fm/play/' + link.split('/audio--')[1].slice(0, 5) + '?options=cc'
//   return newEmbed
// }



class EpisodesPage extends Component {
  render() {
    const data = this.props.data
    const newest = data.latest.edges[0].node

    return (
      <Layout>
        <div className="row contentArea">
          <EpisodeContainer>
            <EpisodeSidebar>
              {data.episodes.edges.map(({ node: show }) => (
                <Link to={`/episodes/${show.itunes.episode}`}>
                  <EpisodeItem key={show.id}>
                    <p>#{ show.itunes.episode } - {show.isoDate}</p>
                    <h3>{ show.title }</h3>
                  </EpisodeItem>
                </Link>
              ))}
            </EpisodeSidebar>


            <CurrentEpisodeStyle>
              <h1>{newest.title}</h1>
              <p style={{textAlign: `center`}}>Episode #{newest.itunes.episode}. Published on {newest.isoDate}</p>

              <iframe src="https://anchor.fm/thingsigotwrong/embed" class="podcast--iframe" title="Things I Got Wrong iframe" frameborder="0" scrolling="no"></iframe>

              {/* <p>{ newest.itunes.summary }</p> */}
              <div	
                dangerouslySetInnerHTML={{ __html: newest.itunes.summary }}	
              />
            </CurrentEpisodeStyle>

          </EpisodeContainer>
        </div>
      </Layout>
    )
  }
}

export default EpisodesPage



export const ALL_EPISODE_QUERY = graphql`
  query AllEpisodeQuery {
    episodes: allFeedGatsbyBlog(sort: {fields: [isoDate], order: DESC})  {
      edges {
        node {
          title
          isoDate(formatString: "MMMM DD, YYYY")
          link
          itunes {
            duration
            episode
            summary
          }
        }
      }
    }
    latest: allFeedGatsbyBlog(sort: {fields: [isoDate], order: DESC}, limit: 1) {
      edges {
        node {
          title
          isoDate(formatString: "MMMM DD, YYYY")
          link
          itunes {
            episode
            duration
            summary
          }
          enclosure {
            url
          }
        }
      }
    }
  }
`
